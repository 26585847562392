
@media only screen and (max-width: 1279px) {


}

@media only screen and (max-width: 991px) {
  .home-card {
    padding: 24px;
    flex-direction: column-reverse;
    &:last-child {
     flex-direction: column;
    }
  }
  .home-sub, .home-cardholder {
    transform: translateY(-48px);
    &:before {
      display: none;
    }
  }
  .home-footer-links {
    flex-direction: column;
    gap: 8px;
    align-items: center;
  }
}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {
  .home-footer-partners {
    flex-direction: column;
  }
  .home-footer-partners_separator {
    display: none;
  }
  .home-form-tabs {
    max-width: calc(100vw - 120px);
    margin: 0 auto;
  }
  .home-form-tab {
    min-width: auto;
    flex: 1;
  }
  .home-form {
    padding: 32px 16px 24px;
  }
  .home-footer {
    margin-top: 64px;
  }
  .home-form-payment-status {
    position: relative;
  }
  .home-form-card {
    padding: 70px 16px 16px;
    border-radius: 16px;
    background: url('../../../../assets/images/card_code.png') no-repeat 16px 24px/ 43px 37px, #D9D9D9;
    .form-label {
      font-size: 12px;
      left: 0;
      right: 0;
      white-space: normal;
    }
    .form-group {
      &:first-child {
        min-width: 95px;
      }
      &:last-child {
        min-width: 68px;
        flex: 1;
      }
    }
    input {
      font-size: 12px;
      &::placeholder {
        font-size: 12px;
      }
    }
  }
  .home-form-card-num {
    font-size: 14px;
  }
  .home-form-card-holder {
    font-size: 16px;
    margin-top: 16px;
  }
}
