
@media only screen and (max-width: 1279px) {
  body {
    font-size: 16px;
    line-height: 20px;
  }
  main {
    padding-top: 64px;
  }
}

@media only screen and (max-width: 991px) {
  main {
    padding-top: 32px;
  }
  h1 {
    font-size: 40px;
  }
  h2, h3 {
    font-size: 24px;
  }
  //.btn {
  //  height: 64px;
  //  font-size: 20px;
  //}
}

@media only screen and (max-width: 767px) {

}

@media only screen and (max-width: 575px) {
  h1 {
    font-size: 24px;
  }
}
