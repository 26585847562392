@font-face {
    font-family: 'Credit-Card';
    src: url('../fonts/CreditCard.eot');
    src: url('../fonts/CreditCard.eot?#iefix') format('embedded-opentype'),
    url('../fonts/CreditCard.woff2') format('woff2'),
    url('../fonts/CreditCard.woff') format('woff'),
    url('../fonts/CreditCard.svg#CreditCard') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Gilroy-Regular';
    src: url('../fonts/Gilroy-Regular.eot');
    src: local('Gilroy Regular'), local('Gilroy-Regular'),
        url('../fonts/Gilroy-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Regular.woff') format('woff'),
        url('../fonts/Gilroy-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy-Bold';
    src: url('../fonts/Gilroy-Bold.eot');
    src: local('Gilroy Bold'), local('Gilroy-Bold'),
        url('../fonts/Gilroy-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Bold.woff') format('woff'),
        url('../fonts/Gilroy-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Gilroy-Black';
    src: url('../fonts/Gilroy-Black.eot');
    src: local('Gilroy Black'), local('Gilroy-Black'),
        url('../fonts/Gilroy-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Gilroy-Black.woff') format('woff'),
        url('../fonts/Gilroy-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

