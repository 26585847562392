.legal-btn-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;

  .btn {
    width: calc(25% - 16px);
  }
}

.legal-information-date {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 16px;
}

.legal-information-content {
  padding-bottom: 40px;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  p {
    margin-bottom: 16px;
    font-size: 14px;
    line-height: 20px;
    color: var(--muted-color);
  }

  ul {
    padding-left: 16px;
    color: var(--muted-color);
    font-size: 14px;
    line-height: 20px;
    &.upper {
      list-style-type: upper-alpha;
    }
    li {
      margin-bottom: 8px;
    }
  }
  b {
    color: rgba(255,255,255,0.9);
  }

  h4 {
    font-family: var(--font-bold);
    margin-top: 12px;
    margin-bottom: 16px;
    text-align: left;
  }
  .table-wrap {
    overflow-x: auto;
  }
  table {
    td {
      font-size: 14px;
      line-height: 18px;
    }
    td, th {
      vertical-align: top;
      padding: 8px;
      &:first-child {
        min-width: 180px;
      }
      &:nth-child(2) {
        min-width: 400px;
      }
      &:last-child {
        min-width: 270px;
      }
    }
  }
  a {
    color: #c36;
  }
}

.legal-info-footer {
  background-color: var(--main-bg-color);
  padding: 24px;
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  font-size: 16px;
  line-height: 20px;
}