::-webkit-scrollbar, iframe body::-webkit-scrollbar, .body-inside-iframe::-webkit-scrollbar {
  width: 2px !important;
  height: 2px !important;
  background-color: transparent !important;
  /*border: 1px solid #d5d5d5 !important;*/
}

::-webkit-scrollbar-thumb, iframe body::-webkit-scrollbar-thumb, .body-inside-iframe::-webkit-scrollbar-thumb {
  background-color: var(--border-color) !important;
  border: 2px solid var(--border-color) !important;
}

html {
  scroll-behavior: smooth;
  /*overflow: hidden;*/
  overflow-x: hidden;
}

body {
  position: relative;
  width: 100vw;
  color: var(--text-color);
  font-family: var(--font-refular);
  font-size: 24px;
  line-height: 32px;
  background-color: #fff;
  margin: 0;
  overflow-x: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  overflow-x: hidden;
}

main {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  width: 100vw;
  padding-top: 98px;

}
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
  color: var(--text-color);
}

h1 {
  position: relative;
  text-wrap: balance;
  text-align: center;
  font-size: 48px;
  /*line-height: 56px;*/
  line-height: normal;
  margin: 0 auto;
  font-family: var(--font-bold);
}
h2 {
  font-size: 32px;
  line-height: normal;
  margin-bottom: 32px;
  font-family: var(--font-bold);
  text-align: center;
}

h3 {
  font-size: 32px;
  line-height: normal;
  margin-bottom: 24px;
  font-family: var(--font-bold);
}

h4 {
  font-size: 16px;
  line-height: 24px;
  font-family: var(--font-bold);
  text-align: center;
}

.btn {
  height: 80px;
  border-radius: 24px;
  transition: var(--transition);
  font-family: var(--font-bold);
  position: relative;
  font-size: 24px;
  line-height: 32px;
  padding-left: 24px;
  padding-right: 24px;
  white-space: nowrap;
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  width: auto;
  &.btn-loading {
    pointer-events: none;
    opacity: 0.8;
  }
}

.btn:active {
  box-shadow: none;
}
.btn.loading {
  filter: grayscale(1);
  opacity: 0.5;
}
.btn:has(.btn-primary-icon) {
  padding-left: 40px;
}

.btn-primary {
  box-shadow: none;
  background-color: var(--text-color);
  border: none;
  color: var(--main-bg-color);
}
.btn-primary:focus {
  box-shadow: none;
  background-color: var(--text-color);
  color: var(--main-bg-color);
}

.btn-primary:active:focus {
  background-color: rgba(25, 28, 35, 0.9);
  box-shadow: none;
}


.btn-primary:hover {
  background-color: rgba(25, 28, 35, 0.9);
  color: var(--main-bg-color);
}
.btn.btn-primary.disabled{
  pointer-events: none;
  background-color: var(--main-bg-color);
  color: var(--secondary-bg-color);
}


ul {
  margin: 0;
  padding-left: 20px;
}

ul li {
  padding-bottom: 8px;
}

p {
  margin-bottom: 0;
  letter-spacing: 0.01em;
  color: var(--muted-color);
}

a {
  color: #464646;
  text-decoration: none;
  outline: none;
  transition: var(--transition);
  font-size: 16px;
  line-height: 20px;
}

a:hover {
  outline: none;
  color: var(--text-color);
}

a:active {
  outline: none;
  color: var(--tint-color);
}

.text-bold {
  font-family: var(--font-bold);
}

.text-bold-16 {
  font-family: var(--font-bold);
  font-size: 16px;
  line-height: 24px;
}

.text-muted {
  color: var(--muted-color) !important;
}
.text-card {
  font-family: var(--font-card);
}

.text-black {
  font-family: var(--font-black);
}

.text-muted-14 {
  color: var(--muted-color);
  font-size: 14px;
  line-height: 18px;
}

.text-16 {
  font-size: 16px;
  line-height: 20px;
}

.text-14 {
  font-size: 14px;
  line-height: 18px;
}

.text-12 {
  font-size: 12px;
  line-height: 18px;
}

.text-tint {
  color: var(--tint-color);
}

.cur-pointer {
  cursor: pointer;
}


.Toastify__toast-theme--light {
  background: var(--main-bg-color);
  color: #fff;
  font-size: 14px;
  line-height: 18px;
  font-family: var(--font-bold);
  display: flex;
  align-items: center;
  border-radius: 0;
  border: 1px solid #43454B;
}

.Toastify__progress-bar--error {
  background: var(--danger);
}

.react-icon > div {
  display: flex;
  align-items: center;
  justify-content: center;
}



.text-truncate-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.flex-1 {
  flex: 1;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.form-group {
  position: relative;
  flex-direction: column;
  display: flex;
  justify-content: center;
  &:has(.form-control-icon) .form-control {
    padding-left: 56px;
  }

}
.form-label {
  font-size: 14px;
  color: var(--muted-color);
  line-height: 20px;
  margin-bottom: 8px;
}
.form-control {
  border: 2px solid var(--secondary-bg-color);
  min-height: 40px;
  padding: 0 20px;
  border-radius: 12px;
  font-size: 16px;
  line-height: 24px;
  box-shadow: none;
  transition: var(--transition);
  width: 100%;
  outline: none!important;
  &::placeholder {
    font-size: 16px;
    line-height: 24px;
    color: var(--muted-color);
  }
  &:focus  {
    box-shadow: none;
    border: 2px solid var(--text-color);
  }
}
.form-control-icon {
  position: absolute;
  pointer-events:  none;
  left: 20px;
  z-index: 1;
}

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  padding-right: 40px;
  min-height: 48px;
  background: url("../../assets/icons/chevron_down.svg") no-repeat calc(100% - 8px) center, rgba(255, 255, 255, 0.1);
  color: var(--text-color);
  border: 2px solid var(--text-color);
  -webkit-border-radius: 24px;
  -moz-border-radius: 24px;
  border-radius: 24px;
  width: auto;
  &::-ms-expand {
    display: none;
  }
  &:has(option[value=""]:not(:checked)) {
    color: #fff !important;
  }
}

