:root {
  --main-bg-color: #F5F5F5;
  --secondary-bg-color: #C9C9C9;
  --text-color: #191C23;
  --tint-color: #FFC700;
  --muted-color: rgba(25, 28, 35, 0.56);
  --border-color: #EAEAEA;

  --danger: #E11E1E;


  --font-refular: 'Gilroy-Regular', sans-serif;
  --font-black: 'Gilroy-Black', sans-serif;
  --font-bold: 'Gilroy-Bold', sans-serif;
  --font-card: 'Credit-Card', sans-serif;

  --transition: all .3s ease;
}
