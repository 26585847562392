.home-page section {
  margin-top: 56px;
}

.home-card {
  background-color: var(--main-bg-color);
  padding: 40px 48px;
  border-radius: 40px;
  margin-top: 40px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.home-card-image {
  flex: 1;
  display: flex;
  justify-content: center;
}

.home-sub {
  width: 320px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: var(--text-color);
    border-radius: 40px;
    transform: translateY(80px) rotate(15deg);
  }

  img {
    transform: rotate(-15deg);
    position: relative;
    max-width: 100%;
  }
}

.home-cardholder {
  width: 270px;
  position: relative;
  margin-right: 60px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: var(--text-color);
    border-radius: 16px;
    transform: translate(-24px, 92px) rotate(30deg);
  }

  img {
    transform: rotate(-15deg);
    position: relative;
    max-width: 100%;
  }
}

.home-form-tabs {
  display: flex;
  justify-content: center;
  gap: 16px;
  font-family: var(--font-bold);
}

.home-form-tab {
  text-align: center;
  min-width: 214px;
  padding: 16px 24px;
  color: var(--muted-color);
  background-color: var(--secondary-bg-color);
  border-radius: 32px 32px 0 0;
  position: relative;
  border: 2px solid var(--secondary-bg-color);
  cursor: pointer;
  border-bottom: 0;
  &:hover {
    color: var(--text-color);
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: -3px;
    height: 4px;
    background-color: var(--main-bg-color);
    opacity: 0;
  }
  &.active {
    color: var(--text-color);
    border-color: var(--border-color);
    background-color: var(--main-bg-color);
    &:before {
      opacity: 1;
    }
  }
}

.home-form {
  background: var(--main-bg-color);
  padding: 64px 32px 80px;
  max-width: 684px;
  width: calc(100vw - 32px);
  margin: 0 auto;
  border-radius: 40px;
  border: 2px solid var(--border-color);

}
.home-form-container {
  max-width: 444px;
  margin: 0 auto;
}

.home-form-security {
  display: flex;
  font-size: 14px;
  line-height: 20px;
  margin-top: 24px;
  p {
    padding-left: 8px;
    margin-left: 12px;
    border-left: 2px solid var(--text-color);
  }
}

.home-footer {
  margin-top: 104px;
  background-color: var(--main-bg-color);
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-footer-partners {
  display: flex;
  margin-top: 12px;
  gap: 8px;
  img {
    height: 48px;
    width: auto;
  }
}
.home-footer-partners_separator {
  width: 1px;
  background: var(--text-color);
  flex: 1;
  border-radius: 2px;
}

.home-footer-links {
  display: flex;
  gap: 40px;
  margin-top: 32px;
  a {
    color: var(--text-color);
    font-size: 16px;
    line-height: 20px;
    font-family: var(--font-bold);
    &:hover {
      color: rgba(25, 28, 35, 0.9);
    }
  }
}
.home-footer-bottom {
  width: 100%;
  margin-top: 40px;
  padding: 8px 0;
  background-color: var(--border-color);
}
.home-footer-bottom-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 72px;
  height: 31px;
  border-radius: 8px;
  background: var(--text-color);
}

.home-form-payment-status {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  text-align: center;
  padding-top: 8px;
  font-size: 14px;
  line-height: 18px;
}
.home-form-card {
  padding: 114px 12px 32px 32px;
  background: url('../../../../assets/images/card_code.png') no-repeat 32px 53px/ 54px 45px, #D9D9D9;
  border-radius: 24px;
  .form-group {
    flex: 0;
    margin-top: 28px;
    &:first-child {
      min-width: 110px;
    }
    &:last-child {
      min-width: 80px;
      input {
        width: 80px;
      }
    }
  }
  .form-label {
    position: absolute;
    margin-bottom: 8px;
    bottom: 100%;
    white-space: nowrap;
  }
  input {
    width: 100%;
    font-size: 14px;
    font-family: var(--font-card) !important;
    padding-right: 0;
    padding-left: 12px;
    &::placeholder {
      font-size: 14px;
      font-family: var(--font-card) !important;
      color: #D9D9D9;
    }
  }
}
.home-form-card-num {
  padding: 0 8px;
  font-size: 32px;
  line-height: 32px;
}
.home-form-card-holder {
  color: #C9C9C9;
  font-size: 24px;
  font-family: var(--font-card);
  margin-top: 32px;
}